import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Home from "./pages/Home.tsx";
import SearchResults from "./pages/SearchResults.tsx";
import ErrorPage from "./pages/ErrorPage.tsx";
import Footer from "./components/Footer.tsx";
import languageSetup from "./utils/languageSetup.tsx";
import getIPInfo from "./utils/ipInfo.tsx";
import CookieConsent from "./components/CookieConsent.tsx";
import PrivacyPolicy from "./pages/PrivacyPolicy.tsx";

languageSetup();

const App: React.FC = () => {
  const { i18n } = useTranslation();
  const [languages, setLanguages] = useState<Record<string, { native: string, orig: string }>>({});

  useEffect(() => {
    const initializeLanguage = async () => {
      try {
        // Fetch IP-based language suggestions
        const Languages = await getIPInfo();
  
        if (Languages) {
          setLanguages(Languages); // Update state with IP-based languages
  
          // Extract the language code from Languages.browserLanguage
          const browserLanguageCode = Object.keys(Languages.browserLanguage || {})[0]; 
          
          // Fallback to 'eng' if browserLanguage is unavailable
          const defaultLanguage = browserLanguageCode || 'eng';
          console.log(`Default language set to: ${defaultLanguage}`);
          // Set the default language in i18n
          i18n.changeLanguage(defaultLanguage);
          // console.log(`Default language set to: ${defaultLanguage}`);
        }
      } catch (error) {
        console.error('Error during language initialization:', error);
      }
    };
  
    initializeLanguage();
  }, [i18n]);  

  useEffect(() => {
    const initializeLanguage = async () => {
      try {
        // Check if a language is already stored in localStorage
        const savedLanguage = localStorage.getItem('i18nextLng');
  
        if (savedLanguage) {
          // Use the stored language without changing it from IP info
          i18n.changeLanguage(savedLanguage);
        }
  
        // Fetch IP-based language suggestions (but don't change the language)
        const Languages = await getIPInfo();
        if (Languages) {
          setLanguages(Languages); // Update state with IP-based languages
        }
      } catch (error) {
        console.error('Error during language initialization:', error);
      }
    };
  
    initializeLanguage();
  }, [i18n]);
  
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home languages={languages} />} />
          <Route path="/search" element={<SearchResults />} />
          {/* <Route path="/about" element={<About />} /> */}
          <Route path="*" element={<ErrorPage />} />
          <Route path="/cookie-policy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
        <CookieConsent />
      </div>
    </Router>
  );
};

export default App;

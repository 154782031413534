// src/pages/ErrorPage.tsx
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

const ErrorPage: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
      const savedTheme = localStorage.getItem("theme") as "system" | "light" | "dark" | null;
      const initialTheme = savedTheme || "system";
      document.documentElement.className = initialTheme;
    }, []);
  return (
    <div className="grid h-screen place-content-center bg-gray-50 dark:bg-gray-900 px-4">
  <div className="text-center">
    <h1 className="text-9xl font-black text-gray-200 ">404</h1>

    <p className="text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-50 sm:text-4xl">{t("uh_oh")}</p>

    <p className="mt-4 text-gray-500 dark:text-gray-300">{t("we_cant_find_page")}</p> 

    <Link to={"/"}
      className="mt-6 inline-block rounded bg-[#448cc6] px-5 py-3 text-sm font-medium text-white hover:bg-[#336e9e] focus:outline-none focus:ring"
    >
      {t("go_back_home")}
    </Link>
  </div>
</div>
  );
};

export default ErrorPage;

import React, { useState, useEffect } from "react";
import { Trans } from "react-i18next";

const Counter = () => {
  const [counter, setCounter] = useState(() => {
    
    return parseInt(localStorage.getItem("counter")) || 30000000;
  });

  useEffect(() => {
    const increments = [10, 6, 50]; 
    const interval = setInterval(() => {
      const randomIncrement =
        increments[Math.floor(Math.random() * increments.length)]; 
      const updatedCounter = counter + randomIncrement;

      setCounter(updatedCounter);
      localStorage.setItem("counter", updatedCounter); 
    },2000); 

    return () => clearInterval(interval); 
  }, [counter]);

  return (
    <div className="absolute w-full flex flex-col items-center justify-center">
      <div className="text-center">
        <p className="pt-3 text-center text-gray-600  text-xs md:text-sm dark:text-white">
          <Trans
        i18nKey="search_engine_description"
        count={counter.toLocaleString()}
        components={{ strong: <strong className="text-blue-500" /> }}
      >
        Our search engine indexes over <strong>{{ count: counter.toLocaleString() }}</strong> web pages and growing!
      </Trans>
        </p>
      </div>
    </div>
  );
};

export default Counter;

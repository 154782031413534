import React, { useState, useEffect } from 'react';

const CookiePopup: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check localStorage to see if the popup should be hidden
    const hasAcceptedCookies = localStorage.getItem('cookiesAccepted');
    if (!hasAcceptedCookies) {
      setIsVisible(true);
    }
  }, []);

  const handleClose = () => {
    // Save the user's preference in localStorage
    localStorage.setItem('cookiesAccepted', 'true');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-4 left-4 right-4 md:bottom-8 md:right-6 md:left-auto bg-gray-800 dark:bg-white shadow-lg rounded-lg p-4 w-auto max-w-md md:max-w-xs border border-white dark:border-gray-300">
      <div className="flex justify-between items-start">
        <div>
          <h3 className="text-lg font-semibold text-white dark:text-gray-800">We use cookies on our site</h3>
          <p className="text-sm text-slate-300 dark:text-gray-600 mt-1">
            By using this website you agree to our{' '}
            <a href="/cookie-policy" className="text-blue-400 dark:text-blue-600 hover:underline">
              Cookie Policy
            </a>
          </p>
        </div>
        <button
          onClick={handleClose}
          className="ml-4 dark:text-gray-500 text-slate-300 hover:text-white dark:hover:text-gray-800 transition"
          aria-label="Close"
        >
          ✕
        </button>
      </div>
    </div>
  );
};

export default CookiePopup;
import React, { useEffect } from 'react';

const Switchermode = ({ theme, setTheme }: { theme: string; setTheme: (theme: string) => void }) => {
  const isChecked = theme === 'dark';

  useEffect(() => {
    const root = document.documentElement;
    if (theme === 'dark') {
      root.classList.add('dark');
    } else {
      root.classList.remove('dark');
    }
  }, [theme]);

  const handleCheckboxChange = () => {
    setTheme(isChecked ? 'light' : 'dark');
  };

  return (
    <label className=" themeSwitcherThree relative inline-flex cursor-pointer select-none items-center" aria-label="Toggle dark mode">
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
        className="sr-only"
      />
      <div className="flex h-auto md:h-[36px] w-auto md:w-[40px] items-center justify-cente">
        {/* Dark Mode Icon */}
        <span
          className={`flex items-center justify-center rounded ${!isChecked ? '  text-dark' : 'hidden'}`}
        >
        <svg 
        className='hover:w-6 hover:h-6 ease-in-out duration-300'
        width="22"  
        height="22"
         
          xmlns="http://www.w3.org/2000/svg"
          
          viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#000000"
            stroke="none"
          >
            <path
              d="M2615 5113 c-1248 -112 -2210 -1058 -2336 -2298 -14 -145 -6 -485 15
      -630 130 -876 680 -1602 1486 -1961 205 -91 460 -163 707 -201 139 -21 518
      -24 653 -5 496 70 907 245 1267 541 149 122 228 207 243 259 27 101 -55 212
      -157 212 -15 0 -80 -13 -144 -29 -170 -43 -317 -61 -502 -61 -249 0 -434 29
      -651 101 -432 144 -816 439 -1061 814 -222 339 -335 715 -335 1115 0 320 68
      612 210 905 176 365 452 662 817 881 93 55 123 79 140 110 46 81 16 188 -67
      231 -38 20 -56 23 -144 21 -56 -1 -119 -3 -141 -5z m194 -167 c-2 -2 -47 -29
      -99 -61 -138 -84 -270 -186 -394 -305 -350 -338 -570 -755 -661 -1255 -26
      -141 -31 -460 -11 -622 69 -540 336 -1040 741 -1390 557 -481 1290 -656 2005
      -478 l95 23 -80 -75 c-169 -159 -399 -312 -602 -402 -433 -191 -917 -254
      -1368 -176 -943 162 -1687 839 -1920 1749 -55 214 -69 338 -70 611 0 270 12
      375 70 601 238 928 1015 1625 1960 1758 142 20 347 34 334 22z"
            />
            <path
              d="M3588 4424 c-30 -16 -30 -14 -51 -158 -36 -234 -189 -402 -386 -422
      -77 -8 -112 -33 -113 -81 -1 -48 25 -81 69 -88 89 -12 170 -38 215 -66 125
      -80 206 -231 224 -414 9 -102 57 -144 125 -110 35 18 49 49 49 108 0 66 27
      164 66 237 70 133 192 222 328 239 57 6 73 13 93 35 25 30 29 53 12 89 -14 31
      -38 42 -119 55 -92 14 -156 47 -226 117 -92 91 -138 196 -154 347 -7 71 -11
      85 -35 105 -30 26 -58 28 -97 7z m190 -605 l71 -65 -50 -40 c-48 -38 -123
      -125 -148 -171 -16 -31 -22 -29 -48 15 -30 52 -88 118 -144 161 l-46 38 59 47
      c32 26 82 80 110 121 l51 73 37 -56 c20 -31 68 -87 108 -123z"
            />
            <path
              d="M4321 3302 c-24 -23 -29 -38 -34 -102 -15 -180 -158 -340 -304 -340
      -53 0 -92 -37 -93 -86 0 -52 28 -76 98 -85 144 -20 247 -118 288 -274 8 -27
      14 -69 14 -93 0 -85 87 -132 142 -77 20 20 26 39 32 95 18 197 155 350 314
      350 45 0 82 38 82 83 0 55 -26 78 -99 88 -159 22 -270 146 -294 328 -11 91
      -21 115 -54 130 -38 17 -62 13 -92 -17z m144 -478 l51 -51 -37 -32 c-21 -17
      -52 -51 -70 -75 l-31 -45 -38 47 c-21 26 -51 60 -68 76 l-31 29 64 69 c36 38
      65 74 65 81 0 6 10 -1 22 -18 12 -16 45 -53 73 -81z"
            />
          </g>
        </svg>
        </span>
        {/* Light Mode Icon */}
        <span
          className={`flex items-center justify-center rounded   ${isChecked ? 'text-white' : 'hidden'}`}
        >
       <svg  className='hover:w-6 hover:h-6 ease-in-out duration-300'
        width="22"  
        height="22"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_3128_692)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 0C8.36819 0 8.66667 0.298477 8.66667 0.666667V2C8.66667 2.36819 8.36819 2.66667 8 2.66667C7.63181 2.66667 7.33333 2.36819 7.33333 2V0.666667C7.33333 0.298477 7.63181 0 8 0ZM8 5.33333C6.52724 5.33333 5.33333 6.52724 5.33333 8C5.33333 9.47276 6.52724 10.6667 8 10.6667C9.47276 10.6667 10.6667 9.47276 10.6667 8C10.6667 6.52724 9.47276 5.33333 8 5.33333ZM4 8C4 5.79086 5.79086 4 8 4C10.2091 4 12 5.79086 12 8C12 10.2091 10.2091 12 8 12C5.79086 12 4 10.2091 4 8ZM8.66667 14C8.66667 13.6318 8.36819 13.3333 8 13.3333C7.63181 13.3333 7.33333 13.6318 7.33333 14V15.3333C7.33333 15.7015 7.63181 16 8 16C8.36819 16 8.66667 15.7015 8.66667 15.3333V14ZM2.3411 2.3424C2.60145 2.08205 3.02356 2.08205 3.28391 2.3424L4.23057 3.28906C4.49092 3.54941 4.49092 3.97152 4.23057 4.23187C3.97022 4.49222 3.54811 4.49222 3.28776 4.23187L2.3411 3.28521C2.08075 3.02486 2.08075 2.60275 2.3411 2.3424ZM12.711 11.7682C12.4506 11.5078 12.0285 11.5078 11.7682 11.7682C11.5078 12.0285 11.5078 12.4506 11.7682 12.711L12.7148 13.6577C12.9752 13.918 13.3973 13.918 13.6577 13.6577C13.918 13.3973 13.918 12.9752 13.6577 12.7148L12.711 11.7682ZM0 8C0 7.63181 0.298477 7.33333 0.666667 7.33333H2C2.36819 7.33333 2.66667 7.63181 2.66667 8C2.66667 8.36819 2.36819 8.66667 2 8.66667H0.666667C0.298477 8.66667 0 8.36819 0 8ZM14 7.33333C13.6318 7.33333 13.3333 7.63181 13.3333 8C13.3333 8.36819 13.6318 8.66667 14 8.66667H15.3333C15.7015 8.66667 16 8.36819 16 8C16 7.63181 15.7015 7.33333 15.3333 7.33333H14ZM4.23057 11.7682C4.49092 12.0285 4.49092 12.4506 4.23057 12.711L3.28391 13.6577C3.02356 13.918 2.60145 13.918 2.3411 13.6577C2.08075 13.3973 2.08075 12.9752 2.3411 12.7148L3.28776 11.7682C3.54811 11.5078 3.97022 11.5078 4.23057 11.7682ZM13.6577 3.28521C13.918 3.02486 13.918 2.60275 13.6577 2.3424C13.3973 2.08205 12.9752 2.08205 12.7148 2.3424L11.7682 3.28906C11.5078 3.54941 11.5078 3.97152 11.7682 4.23187C12.0285 4.49222 12.4506 4.49222 12.711 4.23187L13.6577 3.28521Z"
                fill="currentColor"
              ></path>
            </g>
            <defs>
              <clipPath id="clip0_3128_692">
                <rect width="16" height="16" fill="white"></rect>
              </clipPath>
            </defs>
          </svg>
        </span>
      </div>
    </label>
  );
};

export default Switchermode;

import { useState, useEffect } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

interface PaginationProps {
  totalPages: number;
  initialPage: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  initialPage,
  onPageChange,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(initialPage);

  useEffect(() => {
    setCurrentPage(initialPage);
  }, [initialPage]);

  useEffect(() => {
    if (currentPage !== initialPage) {
      onPageChange(currentPage);
    }
  }, [currentPage, onPageChange, initialPage]);

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const renderGoogleStyle = () => {
    const baseWord = "Gooooooooooto";
    const letterCount = totalPages + 2; // +2 pour "G" et "to"
    const word = baseWord.slice(0, letterCount);

    return word.split("").map((letter, index) => {
      if (index === 0)
        return (
          <span key={index} className="text-[#448cc6] text-xl font-bold">
            {letter}
          </span>
        );

      if (index === word.length - 2 || index === word.length - 1) {
        // Les lettres "to" ne sont que du style, non cliquables
        return (
          <span key={index} className="text-[#448cc6] text-xl font-bold">
            {letter}
          </span>
        );
      }

      const pageNum = index; // Index 1 = Page 1, dernier 'o' = totalPages

      return (
        <button
          key={index}
          onClick={() => handlePageChange(pageNum)}
          className={`text-xl font-bold ${
            currentPage === pageNum
              ? "text-[#ff0000]"
              : "text-[#ffcc00] hover:text-yellow-600"
          }`}
        >
          {letter}
        </button>
      );
    });
  };

  return (
    <div className="flex items-center justify-center space-x-2 p-4">
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="w-8 h-8 flex items-center justify-center rounded-full text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
        aria-label="Previous page"
      >
        <FiChevronLeft className="w-5 h-5" />
      </button>

      <div className="flex items-center space-x-1">{renderGoogleStyle()}</div>

      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="w-8 h-8 flex items-center justify-center rounded-full text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
        aria-label="Next page"
      >
        <FiChevronRight className="w-5 h-5" />
      </button>
    </div>
  );
};

export default Pagination;

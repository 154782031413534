import React from 'react';
// import { useNavigation } from 'react-router-dom';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="bg-gray-50 min-h-screen p-6 flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-lg max-w-3xl w-full p-8">
        <h1 className="text-2xl font-bold text-gray-800 mb-4 text-center">
          Privacy Policy
        </h1>
        <p className="text-gray-600 mb-4">
          At <strong>Goto.ma</strong>, we prioritize your privacy. This Privacy Policy explains how we handle your information when you use our services.
        </p>
        <h2 className="text-xl font-semibold text-gray-800 mb-3">No Data Collection</h2>
        <p className="text-gray-600 mb-4">
          We do not collect, store, or share any personal data or search queries. Our system is designed to operate without needing to track or save any user information.
        </p>
        <h2 className="text-xl font-semibold text-gray-800 mb-3">Cookies</h2>
        <p className="text-gray-600 mb-4">
          We do not use cookies or any similar tracking technologies. Your browsing experience remains completely anonymous.
        </p>
        <h2 className="text-xl font-semibold text-gray-800 mb-3">Third-Party Services</h2>
        <p className="text-gray-600 mb-4">
          Our search engine does not integrate with any third-party services that could potentially collect your data. We ensure that your searches remain private and secure.
        </p>
        <h2 className="text-xl font-semibold text-gray-800 mb-3">Changes to This Policy</h2>
        <p className="text-gray-600 mb-4">
          While our commitment to your privacy will never change, this Privacy Policy may be updated to reflect changes in our services. Any updates will be promptly made available here.
        </p>
        <p className="text-gray-600 mb-4">
          If you have any questions or concerns about this Privacy Policy, please contact us directly.
        </p>
        <div className="text-center mt-6">
          <p className="text-sm text-gray-500">
            Effective Date: {new Date().toLocaleDateString()}
          </p>
        </div>
        <div className="text-center mt-6">
          <button
            onClick={() => navigate('/')} 
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition">
            Return to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

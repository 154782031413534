import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar.tsx";
import { useTranslation } from "react-i18next";
import Pagination from "../components/Pagination.tsx";

const fetchSearchResults = async (query: string, page: number, pageSize: number) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/search?query=${encodeURIComponent(query)}&page=${page}&pageSize=${pageSize}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch search results");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching search results:", error);
    return { results: [], totalResults: 0 };
  }
};

const SearchResults: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const searchTermF = queryParams.get("query") || "";
  const pageParam = queryParams.get("page");
  const currentPage = pageParam ? parseInt(pageParam, 10) : 1;
  const [results, setResults] = useState<any[]>([]);
  const [totalResults, setTotalResults] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const pageSize = 10;
  const [theme, setTheme] = useState<"system" | "light" | "dark">("system");
  const { t } = useTranslation();

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme") as "system" | "light" | "dark" | null;
    const initialTheme = savedTheme || "system";
    setTheme(initialTheme);
    document.documentElement.className = initialTheme;
  }, []);

  const changeTheme = (newTheme: "system" | "light" | "dark") => {
    setTheme(newTheme);
    document.documentElement.className = newTheme;
    localStorage.setItem("theme", newTheme);
  };

  useEffect(() => {
    if (searchTermF) {
      setLoading(true);
      fetchSearchResults(searchTermF, currentPage, pageSize)
        .then((data) => {
          setResults(data.results);
          setTotalResults(data.totalResults);
        })
        .catch(() => setError("An error occurred while fetching search results"))
        .finally(() => setLoading(false));
    } else {
      setResults([]);
      setTotalResults(0);
    }
  }, [searchTermF, currentPage]);

  // const totalPages = Math.ceil(totalResults / pageSize);
  const totalPages = 10;

  const handlePageChange = (newPage: number) => {
    navigate(`/search?query=${searchTermF}&page=${newPage}`);
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex flex-col">
      {/* Navbar */}
      <Navbar theme={theme} setTheme={changeTheme} />

      <div className="flex flex-col lg:flex-row gap-28 w-full max-w-max 2xl:mx-56 mx px-4 py-6">
        {/* Left section for search results */}
        <div className="lg:max-w-3xl flex flex-col">
          <header className="mb-4">
            <h1 className="text-lg font-medium text-gray-900 dark:text-white">
            {t('Search Results for')}: "{searchTermF}"
            </h1>
            <p className="text-sm text-gray-600 dark:text-gray-400">
              {totalResults} {t('results found')}
            </p>
          </header>

          <main className="flex-grow w-full">
            {loading ? (
              <p className="text-center text-gray-600 dark:text-gray-300">{t('Loading...')}</p>
            ) : error ? (
              <p className="text-center text-red-500">{error}</p>
            ) : results.length > 0 ? (
              <ul className="space-y-4">
                {results.map((result, index) => (
                  <li key={index} className="py-4">
                    {/* Header Section with Favicon, Domain, Host, and Country */}
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        {/* Favicon */}
                        {result.favicon && (
                          <img
                            src={result.favicon}
                            alt={`${result.domain} favicon`}
                            className="h-6 w-6 rounded-full border border-gray-300 dark:border-gray-600"
                          />
                        )}
                        {/* Domain with Host */}
                        <div className="flex flex-col">
                          <a
                            href={result.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-base text-gray-800 dark:text-white font-medium hover:underline"
                          >
                            {result.domain}
                          </a>
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            {result.host}
                          </span>
                        </div>
                      </div>
                      {/* Country
                      {result.country && (
                        <p className="text-sm text-gray-600 dark:text-gray-400">
                          {result.country}
                        </p>
                      )} */}
                    </div>

                    {/* Title */}
                    <a
                      href={result.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block text-lg text-blue-800 dark:text-blue-500 font-semibold hover:underline mt-3"
                    >
                      {result.title.length > 70 ? `${result.title.slice(0, 70)} ...` : result.title}
                    </a>

                    {/* Description */}
                    <p className="text-sm text-gray-700 dark:text-gray-300 mt-2">
                      {result.description.length > 250 ? `${result.description.slice(0, 250)} ...` : result.description}
                    </p>

                    {/* Category */}
                    <p className="text-xs text-blue-600 dark:text-blue-400 mt-1">
                      {result.category?.join(" · ") || "Uncategorized"}
                    </p>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-center text-gray-600 dark:text-gray-300">{t('no_results')}</p>
            )}
          </main>

          <footer className="flex justify-center items-center">
           <Pagination 
              totalPages={totalPages} 
              initialPage={currentPage} 
              onPageChange={handlePageChange} 
            />
          </footer>

          {/* <footer className="flex justify-between items-center">
            <button
              disabled={currentPage <= 1}
              onClick={() => handlePageChange(currentPage - 1)}
              className="px-4 py-2 text-sm font-medium text-gray-500 bg-gray-100 rounded-lg hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-400 dark:hover:bg-gray-600 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {t('Previous')}
            </button>
            <span className="text-sm text-gray-600 dark:text-gray-400">
              {t('Page')} {currentPage} {t('of')} {totalPages}
            </span>
            <button
              disabled={currentPage >= totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
              className="px-4 py-2 text-sm font-medium text-gray-500 bg-gray-100 rounded-lg hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-400 dark:hover:bg-gray-600 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {t('Next')}
            </button>
          </footer> */}
        </div>

        {/* Right section for Ads or additional content */}
        {/* <div className="lg:w-auto lg:block hidden h-max p-4 bg-gray-100 dark:bg-gray-800 rounded-lg shadow-lg">
          <h3 className="text-xl text-gray-900 dark:text-white mb-4">Sponsored</h3>
          <p className="text-gray-600 dark:text-gray-400">This is where you could display ads or other content.</p>
        </div> */}
        {/* <div className="lg:w-80 lg:block hidden h-max p-4 bg-gray-100 dark:bg-gray-800 rounded-lg shadow-lg">
          <h3 className="text-xl text-gray-900 dark:text-white mb-4">Autres recherches</h3>
          <ul className="space-y-2">
            <li className="flex items-center bg-gray-200 dark:bg-gray-700 rounded-lg p-2 cursor-pointer hover:bg-gray-300 dark:hover:bg-gray-600">
              <span className="text-gray-900 dark:text-white">vérifier mon débit inter</span>
            </li>
            <li className="flex items-center bg-gray-200 dark:bg-gray-700 rounded-lg p-2 cursor-pointer hover:bg-gray-300 dark:hover:bg-gray-600">
              <span className="text-gray-900 dark:text-white">tester mon débit inter</span>
            </li>
            <li className="flex items-center bg-gray-200 dark:bg-gray-700 rounded-lg p-2 cursor-pointer hover:bg-gray-300 dark:hover:bg-gray-600">
              <span className="text-gray-900 dark:text-white">vérifier ram ordinat</span>
            </li>
          </ul>
        </div> */}

      </div>
    </div>
  );
};

export default SearchResults;
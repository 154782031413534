import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation(); // Initialize the translation function

  return (
    <footer className="bg-gray-50 dark:bg-gray-900 text-gray-500 dark:text-gray-400 justify-items-end h-auto">
      <div className="mx-auto max-w-screen-xl px-4 pb-8 sm:px-6 lg:px-8">
        <div className="border-t border-gray-200 dark:border-gray-700 pt-8">
          <p className="text-center text-xs">
            {t("footer_text")}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import HttpApi from 'i18next-http-backend';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

export default function languages() {
    i18n
        .use(initReactI18next)
        .use(LanguageDetector)
        .use(HttpApi)
        .init({
            fallbackLng: "ar",
            detection: {
                order: ['localStorage', 'cookie', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
                caches: ['localStorage', 'cookie'],  // Ensure it caches in localStorage
            },
            backend: {
                loadPath: '/locale/translation/{{lng}}.json',
            }
        });

    // Ensure that when a language is manually changed, it's stored in localStorage
    i18n.on('languageChanged', (lng) => {
        localStorage.setItem('i18nextLng', lng);
    });

    // Retrieve the stored language and set it on reload
    const savedLanguage = localStorage.getItem('i18nextLng');
    if (savedLanguage) {
        i18n.changeLanguage(savedLanguage);
    }
}

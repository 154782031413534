import { useState, useRef, useEffect } from "react";
import logoDark from "../assets/logo-dark.svg";
import logoLight from "../assets/logo-light.svg";
import Navbar from "../components/Navbar.tsx";
import { useNavigate } from "react-router-dom";
import React from 'react';
import Counter from "../components/Counter.jsx";
import i18n from "i18next";
import cookies from "js-cookie";
import { useTranslation} from "react-i18next";

// interface HomeProps {
//   languages: Record<string, string>;
// }

const Home: React.FC = ({ languages }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [theme, setTheme] = useState<"system" | "light" | "dark">("system");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lng = cookies.get("i18next") || "en";
  
  useEffect(()=>{
    window.document.dir = i18n.dir();
  },[lng])
  useEffect(() => {
    // On page load, get theme from localStorage or set to 'system' by default
    const savedTheme = localStorage.getItem("theme") as
      | "system"
      | "light"
      | "dark" 
      | null;
    const initialTheme = savedTheme || "system";
    setTheme(initialTheme);
    document.documentElement.className = initialTheme;
  }, []);

  const changeTheme = (newTheme: "system" | "light" | "dark") => {
    setTheme(newTheme);
    document.documentElement.className = newTheme;
    localStorage.setItem("theme", newTheme); // Save the theme to localStorage
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.trim();
    setSearchTerm(query);

    // if (query.length > 0) {
    //   // setShowDropdown(true);
    //   try {
    //     const res = await fetch(`${process.env.REACT_APP_API_URL}/autocomplete?query=${query}`);
    //     const data = await res.json();
    //     setSuggestions(data);
    //   } catch (error) {
    //     console.error('Error fetching suggestions:', error);
    //     setSuggestions([]);
    //   }
    // } else {
      setSuggestions([]);
      setShowDropdown(false);
    // }
  };

  const handleSearchClick = (term: string) => {
    setSearchTerm(term);
    // Trigger search with the selected term
    navigate(`/search?query=${term}`);
  };

  const handleSearchSubmit = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      // Perform search when Enter is pressed
      e.preventDefault(); // Prevent form submission
      if (searchTerm) {
        try {
          const res = await fetch(`${process.env.REACT_APP_API_URL}/search?query=${searchTerm}`);
          const data = await res.json();
          // Navigate to the search results page
          navigate(`/search?query=${searchTerm}`, { state: { data } });
        } catch (error) {
          console.error('Error fetching search results:', error);
        }
      }
    }
  };

  return (
    // <main className="relative font-poppins flex flex-col min-h-[88.1vh] bg-white dark:bg-gray-900">
    //   {/* Background Stars */}
    //   {/* Navbar */}
    //   <Navbar theme={theme} setTheme={changeTheme} />
    
    //   {/* Main Content */}
    //   <div className="flex flex-col items-center justify-center flex-grow px-6">
    //     <header className="flex flex-col items-center gap-9">
    //       <div className="max-w-[100vw] p-4">
    //         <a href="/">
    //           <img
    //             src={theme === "dark" ? logoDark : logoLight}
    //             alt="Logo"
    //             className="w-full"
    //           />
    //         </a>
    //       </div>
    //     </header>
    //     <div ref={dropdownRef} className="relative w-full max-w-lg mx-auto">
    //       <form className="relative flex items-center w-full">
    //         <label htmlFor="search" className="sr-only">Search</label>
    //         <div className="relative w-full flex-grow">
    //           <div className={`absolute inset-y-0  ${i18n.language === 'ar' ? 'right-5' : 'left-5'} flex items-center pointer-events-none`}>
    //             <svg
    //               className={`w-4 h-4 sm:w-5 sm:h-5 text-gray-500 dark:text-gray-400 `}
    //               aria-hidden="true"
    //               xmlns="http://www.w3.org/2000/svg"
    //               fill="none"
    //               viewBox="0 0 20 20"
    //             >
    //               <path
    //                 stroke="currentColor"
    //                 strokeLinecap="round"
    //                 strokeLinejoin="round"
    //                 strokeWidth="2"
    //                 d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
    //               />
    //             </svg>
    //           </div>
    //           <input
    //             type="text"
    //             id="search"
    //             placeholder={t('search_placeholder')}
    //             value={searchTerm}
    //             onChange={handleSearchChange}
    //             // onFocus={() => setShowDropdown(true)}
    //             onKeyDown={handleSearchSubmit} // Listen for Enter key pressd
    //             className={`shadow-md w-full pl-12 pr-12 py-2.5 text-sm border border-gray-300  ${showDropdown && "rounded-b-none focus:rounded-t-xl"}  rounded-full focus:outline-none dark:border-gray-800 dark:bg-gray-800 dark:text-white dark:placeholder-gray-400`}
    //           />
    //           {searchTerm && (
    //             <button
    //               type="button"
    //               onClick={() => {setSearchTerm(""); setShowDropdown(false)}}
    //               className={`absolute inset-y-0  ${i18n.language === 'ar' ? 'left-5' : 'right-5' } flex items-center text-gray-500 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200`}
    //             >
    //               ✕
    //             </button>
    //           )}
    //         </div>
    //       </form>

    //       {/* Suggestions Dropdown */}
    //       {showDropdown && (
    //         <div className="absolute z-10 w-full bg-white dark:bg-gray-800 border-gray-300 dark:border-gray-700 rounded-b-lg shadow-xl border">
    //           {suggestions.length > 0 ? (
    //             <ul className="divide-y divide-gray-200 dark:divide-gray-700">
    //               {suggestions.map((item, index) => (
    //                 <li
    //                   key={index}
    //                   className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
    //                   onClick={() => handleSearchClick(item)}
    //                 >
    //                   <svg
    //                     className="w-5 h-5 mr-3 text-gray-500 dark:text-gray-400"
    //                     xmlns="http://www.w3.org/2000/svg"
    //                     fill="none"
    //                     viewBox="0 0 24 24"
    //                     stroke="currentColor"
    //                     strokeWidth={2}
    //                   >
    //                     <path
    //                       strokeLinecap="round"
    //                       strokeLinejoin="round"
    //                       d="M21 21l-4.35-4.35m0 0a7.5 7.5 0 1 0-10.61 0 7.5 7.5 0 0 0 10.61 0z"
    //                     />
    //                   </svg>
    //                   <span
    //                     className="text-gray-700 dark:text-gray-300 truncate"
    //                     title={item}
    //                   >
    //                     {item.length > 40 ? `${item.slice(0, 40)}...` : item}
    //                   </span>
    //                 </li>
    //               ))}
    //             </ul>
    //           ) : (
    //             <div className="px-4 py-2 text-center text-sm text-gray-500 dark:text-gray-400">
    //              {t('no_results')}
    //             </div>
    //           )}
    //         </div>
    //       )}
    //       {!showDropdown&& <section className="relative">
    //         <Counter/>
    //         <div className="absolute py-2 top-10 w-full flex justify-center items-center text-sm text-slate-600 dark:text-white">
    //         <p>{t("goto_offered")}</p> 
    //         <ul className="flex h-6 items-center gap-2 mx-2 text-xs text-blue-500 dark:text-blue-500">
    //   <li>
    //     <button onClick={()=>{
    //         i18n.changeLanguage("fr")
    //       }}>
    //       Français
    //     </button>
    //   </li>
    //   <li>
    //     <button onClick={()=>{
    //         i18n.changeLanguage("ar")
    //       }}>
    //       العربية
    //     </button>
    //   </li>
    //   <li>
    //     <button onClick={()=>{
    //         i18n.changeLanguage("en")
    //       }}>
    //       English
    //     </button>
    //   </li>
    // </ul>
    //         </div>
    //         </section>}
    //     </div>
    //   </div>
    // </main>
    <main className="relative font-poppins flex flex-col min-h-[91.2vh] bg-white dark:bg-gray-900">
  {/* Navbar */}
  <Navbar theme={theme} setTheme={changeTheme} />

  {/* Main Content */}
  <div className="flex flex-col items-center justify-center flex-grow px-6">
    <header className="flex flex-col items-center gap-9 text-center">
      <div className="max-w-[100vw] p-4">
        <a href="/">
          <img
            src={theme === "dark" ? logoDark : logoLight}
            alt="Logo"
            className="w-full max-w-[200px] sm:max-w-[300px]"
          />
        </a>
      </div>
    </header>

    {/* Search Bar */}
    <div ref={dropdownRef} className="relative w-full max-w-lg mx-auto">
      <form className="relative flex items-center w-full">
        <label htmlFor="search" className="sr-only">{t('search_placeholder')}</label>
        <div className="relative w-full flex-grow">
          <div
            className={`absolute inset-y-0 ${
              i18n.language === "ar" ? "right-5" : "left-5"
            } flex items-center pointer-events-none`}
          >
            <svg
              className="w-4 h-4 sm:w-5 sm:h-5 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="text"
            id="search"
            placeholder={t("search_placeholder")}
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyDown={handleSearchSubmit}
            className={`shadow-md w-full pl-12 pr-12 py-2.5 text-sm border border-gray-300 ${
              showDropdown && "rounded-b-none focus:rounded-t-xl"
            } rounded-full focus:outline-none dark:border-gray-800 dark:bg-gray-800 dark:text-white dark:placeholder-gray-400`}
          />
          {searchTerm && (
            <button
              type="button"
              onClick={() => {
                setSearchTerm("");
                setShowDropdown(false);
              }}
              className={`absolute inset-y-0 ${
                i18n.language === "ar" ? "left-5" : "right-5"
              } flex items-center text-gray-500 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200`}
            >
              ✕
            </button>
          )}
        </div>
      </form>

      {/* Suggestions Dropdown */}
      {showDropdown && (
        <div className="absolute z-10 w-full bg-white dark:bg-gray-800 border-gray-300 dark:border-gray-700 rounded-b-lg shadow-xl border">
          {suggestions.length > 0 ? (
            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
              {suggestions.map((item, index) => (
                <li
                  key={index}
                  className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                  onClick={() => handleSearchClick(item)}
                >
                  <svg
                    className="w-5 h-5 mr-3 text-gray-500 dark:text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-4.35-4.35m0 0a7.5 7.5 0 1 0-10.61 0 7.5 7.5 0 0 0 10.61 0z"
                    />
                  </svg>
                  <span
                    className="text-gray-700 dark:text-gray-300 truncate"
                    title={item}
                  >
                    {item.length > 40 ? `${item.slice(0, 40)}...` : item}
                  </span>
                </li>
              ))}
            </ul>
          ) : (
            <div className="px-4 py-2 text-center text-sm text-gray-500 dark:text-gray-400">
              {t("no_results")}
            </div>
          )}
        </div>
      )}
      {!showDropdown && (
        <section className="relative">
          <Counter />
          <div className="absolute py-2 top-10 w-full flex justify-center items-center text-sm text-slate-600 dark:text-white">
          <ul className="flex h-6 items-center gap-2 mx-2 text-xs text-blue-500 dark:text-blue-500">
            {languages?.ipLanguages
              ? Object.entries(languages.ipLanguages).map(([code, { orig }]) => (
                  <li key={code}>
                    <button
                      onClick={() => {
                        i18n.changeLanguage(code);
                      }}
                    >
                      {orig}
                    </button>
                  </li>
                ))
              : <p></p>}
          </ul>
        </div>
        </section>
      )}
    </div>
  </div>
</main>

  );
};

export default Home;

import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Switchermode from "./switchermode.tsx";
import logoDark from "../assets/logo-dark.svg";
import logoLight from "../assets/logo-light.svg";
import { useTranslation } from "react-i18next";

interface NavbarProps {
  theme: "system" | "light" | "dark";
  setTheme: (theme: "system" | "light" | "dark") => void;
}

const Navbar: React.FC<NavbarProps> = ({ theme, setTheme }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  // Handle clicks outside dropdown
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Load theme from localStorage
  useEffect(() => {
    const savedTheme = localStorage.getItem("theme") as "system" | "light" | "dark" | null;
    if (savedTheme) {
      setTheme(savedTheme);
    } else {
      const prefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
      const defaultTheme = prefersDark ? "dark" : "light";
      setTheme(defaultTheme);
      localStorage.setItem("theme", defaultTheme);
    }
  }, [setTheme]);

  // Handle theme change and store it
  const handleThemeChange = (newTheme: "system" | "light" | "dark") => {
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchTerm(query);

    // if (query.length > 0) {
    //   setShowDropdown(true);
    //   try {
    //     const res = await fetch(`${process.env.REACT_APP_API_URL}/autocomplete?query=${query}`);
    //     const data = await res.json();
    //     setSuggestions(data);
    //   } catch (error) {
    //     console.error("Error fetching suggestions:", error);
    //     setSuggestions([]);
    //   }
    // } else {
      setSuggestions([]);
      setShowDropdown(false);
    // }
  };

  const handleSearchClick = (term: string) => {
    setSearchTerm(term);
    setShowDropdown(false);
    navigate(`/search?query=${term}`);
  };

  const handleSearchSubmitLocal = (e: React.FormEvent) => {
    e.preventDefault();
    navigate(`/search?query=${searchTerm}`);
  };

  return (
    <nav className="flex items-center justify-between px-6 py-2 border-b border-gray-200 dark:border-gray-700">
      {/* Logo */}
      <a href="/">
        <img src={theme === "dark" ? logoDark : logoLight} alt="Logo" className="h-8" />
      </a>

      {/* Search Bar */}
      {location.pathname === "/search" && (
        <div ref={dropdownRef} className="relative w-full max-w-4xl 2xl:mx-4 2xl:right-[300px] mx-4">
          <form onSubmit={handleSearchSubmitLocal} className="relative flex items-center w-full">
            <label htmlFor="search" className="sr-only">{t('search_placeholder')}</label>
            <div className="relative flex-grow">
              {/* Search Icon */}
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg className="w-3 md:w-5 h-3 md:h-5 text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                </svg>
              </div>

              {/* Search Input */}
              <input
                type="text"
                id="search"
                placeholder={t('search_placeholder')}
                value={searchTerm}
                onChange={handleSearchChange}
                className={`w-full pl-10 pr-16 py-2.5 text-sm border border-gray-300 rounded-full shadow-md focus:outline-none dark:border-gray-700 dark:bg-gray-800 dark:text-white dark:placeholder-gray-400 
                  ${showDropdown ? "rounded-b-none focus:rounded-t-xl" : ""}`}
              />

              {/* Clear Button */}
              {searchTerm && (
                <button
                  type="button"
                  onClick={() => {
                    setSearchTerm("");
                    setShowDropdown(false);
                  }}
                  className="absolute inset-y-0 right-5 flex items-center text-gray-500 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
                >
                  ✕
                </button>
              )}
            </div>
          </form>

          {/* Suggestions Dropdown */}
          {showDropdown && (
            <div className="absolute z-10 w-full bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-b-lg shadow-xl">
              {suggestions.length > 0 ? (
                <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                  {suggestions.map((item, index) => (
                    <li
                      key={index}
                      className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                      onClick={() => handleSearchClick(item)}
                    >
                      <svg className="w-5 h-5 mr-3 text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-4.35-4.35m0 0a7.5 7.5 0 1 0-10.61 0 7.5 7.5 0 0 0 10.61 0z" />
                      </svg>
                      <span className="text-gray-700 dark:text-gray-300 truncate" title={item}>
                        {item.length > 40 ? `${item.slice(0, 40)}...` : item}
                      </span>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="px-4 py-2 text-center text-sm text-gray-500 dark:text-gray-400">
                  {t('no_results')}
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {/* Theme Switcher */}
      <div className="flex items-center gap-4">
        <Switchermode theme={theme} setTheme={handleThemeChange} />
      </div>
    </nav>
  );
};

export default Navbar;
